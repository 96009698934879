/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-08-12",
    versionChannel: "nightly",
    buildDate: "2023-08-12T00:18:54.255Z",
    commitHash: "70b43fc2ff88e2ff7da985e58afb4fa6034ad9ef",
};
